/* Google Font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

/* Stye */

body,
*,
button {
    font-family: 'Inter', sans-serif;
}

nav{
    display: flex;
    align-items: center;
    border-bottom: solid 1px rgba(249, 249, 249, 0.40);

    .logo{
        width: 107px;
        height: 107px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        border-right: solid 1px rgba(249, 249, 249, 0.40);

        img{
            width: 63px;
            height: 63px;
            object-fit: contain;
            filter: saturate(0) brightness(4);
            opacity: 0.4;
        }
    }

    .menu {
        width: 107px;
        height: 107px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        margin: 0 0 0 auto;
        border: none;
        background: transparent;
        cursor: pointer;
        padding: 0 30px;
        border-left: solid 1px rgba(249, 249, 249, 0.40);

        span {
            width: 100%;
            height: 2px;
            display: block;
            background: #637780;
            transition: all 0.4s ease-in-out;

            &:nth-child(2n) {
                width: 75%;
            }

            &:nth-child(3n) {
                width: 50%;
            }
        }

        &:hover {
            span {
                width: 100%;
                background: #ABABAB;

            }
        }
    }

    .links {
        // display: flex;
        gap: 50px;
        color: #627D89;
        font-size: 15.404px;
        font-weight: 400;
        text-transform: uppercase;
        padding: 0 30px;

        a {
            text-decoration: none;
            color: #627D89;
            transition: all 0.3s ease-in;
            display: inline-flex;
            align-items: center;
            gap: 12px;

            img {
                transition: all 0.4s ease-in;
            }

            &:hover {
                color: #ABABAB;

                img {
                    transform: rotate(95deg);
                }
            }
        }
    }
    @media only screen and (min-width: 990px) {
        .links {
            display: flex;
            gap: 50px;
            color: #627D89;
            font-size: 15.404px;
            font-weight: 400;
            text-transform: uppercase;
            padding: 0 80px;
    
            a {
                text-decoration: none;
                color: #627D89;
                transition: all 0.3s ease-in;
                display: inline-flex;
                align-items: center;
                gap: 12px;
    
                img {
                    transition: all 0.4s ease-in;
                }
    
                &:hover {
                    color: #ABABAB;
    
                    img {
                        transform: rotate(95deg);
                    }
                }
            }
        }
      }
      
}

.mainWrapper {
    background: radial-gradient(50% 50.00% at 50% 50.00%, #072735 0%, #01151E 100%);
    min-height: 100vh;
}
.bgimg {
    background-image: url('../websiteImg/ModelBackground.svg');
    background-color: #01151E;
    background-position: center;
    background-size: cover;
    
  
  }
//   .side-nav{
 
//     align-items: center;
//     border-right: solid 1px rgba(249, 249, 249, 0.40);
//     flex-direction: column;
//     display: flex;
//     text-transform: uppercase;
//     font-weight: normal;
//     letter-spacing: normal;
//     align-items: center;
//     justify-content: space-around;
//     transform: none;
//     color: white;
//     height: 100%;
//   width: 110px;
  

//     // gap: 80px;

//   }
.sidenav{
    min-height: calc(100vh - 182px);
    
    
    }
    // .active {
    //     color:black ;
    
    //   }