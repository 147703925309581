.slider {
  .slick-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    top: 103%;
    right: 21%;
    padding: 0;

    @media (min-width: 992px) {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
      margin: 0;
      top: 103%;
      right: auto;
      padding: 0;
    }

    @media (min-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
      margin: 0;
      top: 103%;
      right: auto;
      padding: 0;
    }
    
    @media (min-width: 568px) {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
      margin: 0;
      top: 103%;
      right: auto;
      padding: 0;
    }
  }


  .slick-dots li {
    margin: 0 5px;
  }

  .slick-dots li.slick-active button {
    background-color: #00B1FF;
  }

  .slick-dots li button {
    background-color: #637780;
    border: none;
    border-radius: 50%;
    width: 9px;
    height: 9px;
    cursor: pointer;
  }
}

.slider {

  .slick-prev,
  .slick-next {
    font-size: 0;
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 100%;
    z-index: 1;
  }


  .slick-prev:before {
    content: "";
    display: block;
    width: 20px;
    height: 10px;
    background-image: url("../websiteImg/sliderarrowprev.svg");
    background-size: cover;
  }

  .slick-next:before {
    content: "";
    display: block;
    width: 20px;
    height: 10px;
    background-image: url("../websiteImg/slidericonnext.svg");
    background-size: cover;

  }

  .slick-prev {
    left: 30px;
    top: 107%;
    @media (min-width: 768px) {
      left: 140px;
      top: 107%;
    }
    @media (min-width: 992px) {
      left: 130px;
      top: 107%;
    }
    @media (min-width: 568px) {
      left: 130px;
      top: 107%;
    }
  }


  .slick-next {
    right: 210px;
    top: 107%;
    @media (min-width: 768px) {
      right: 110px;
      top: 107%;
    }
    @media (min-width: 992px) {
      right: 110px;
      top: 107%;
    }
    @media (min-width: 568px) {
      right: 110px;
      top: 107%;
    }
  }

}

.client_slider {

  .slick-prev,
  .slick-next {
    font-size: 0;
    background-color: transparent;
    border: none;
    top: 100%;
    z-index: 1;
  }


  .slick-prev:before {
    content: "";
    display: block;
    width: 10px;
    height: 20px;
    background-image: url("../websiteImg/client_slider_iconleft.png");
    background-size: cover;
  }

  .slick-next:before {
    content: "";
    display: block;
    width: 10px;
    height: 20px;
    background-image: url("../websiteImg/client_slider_iconright.png");
    background-size: cover;

  }

  .slick-prev {
    top: 50%;
    @media (min-width: 768px) {
      top: 50%;
    }
    @media (min-width: 992px) {
      top: 50%;
    }
  }
  .slick-next {
    top: 50%;
    @media (min-width: 768px) {
      top: 50%;
    }
    @media (min-width: 992px) {
      top: 50%;
    }
  }
}
